import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ETipoJogo, IResultado } from 'app/shared/models/interface.model';
import { ESessionLoteria } from 'app/shared/models/sessionstorage.model';
import { ResultadoService } from 'app/shared/services/resultado.service';
import { Subject, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-header-loteria',
  templateUrl: './header-loteria.component.html',
  styleUrls: ['./header-loteria.component.scss'],
})
export class HeaderLoteriaComponent implements OnInit, OnChanges {
  @Input('jogo') jogo: ETipoJogo = ETipoJogo.LOTOFACIL;
  @Input('titulo') titulo = 'Lotofácil';
  @Input('concurso') concurso = new Subject<string>();

  @Output() ultimo = new EventEmitter();

  ultimoResultado: number = 0;

  resultado: IResultado = {} as IResultado;

  constructor(private _resultadoService: ResultadoService) {
    this.concurso.pipe(distinctUntilChanged()).subscribe(() => {
      this.carregaResultado();
    });
  }

  ngOnInit(): void {
    this.resultado.dezenas = [];
    this.carregaResultado();
  }
  ngOnChanges(): void {
    this.resultado.dezenas = [];
    this.carregaResultado(true);
  }

  carregaResultado(consultarAPI = false): void {
    let resultado = sessionStorage.getItem(
      ESessionLoteria.RESULTADO_ULTIMO + this.jogo
    );

    if (resultado && !consultarAPI) {
      this.resultado = JSON.parse(resultado);
      if (this.ultimoResultado === 0) {
        this.ultimoResultado = this.resultado.concurso;
      }
      this.ultimo.emit(this.resultado);
      return;
    }

    this._resultadoService
      .getResultadoConcurso(this.jogo, this.concurso.toString())
      .subscribe({
        next: (response) => {
          this.resultado = response;
          sessionStorage.setItem(
            ESessionLoteria.RESULTADO_ULTIMO + this.jogo,
            JSON.stringify(response)
          );
          if (this.ultimoResultado === 0) {
            this.ultimoResultado = response.concurso;
          }
          this.ultimo.emit(this.resultado);
        },
        error: (err) => console.log(err),
      });
    return;
  }

  pularLinha(index: number): boolean {
    if (index === 0) return false;

    if (this.jogo === 'timemania' || this.jogo === 'diadesorte') {
      if (index == 4) return true;
      return false;
    }

    if (this.jogo === 'supersete') return false;

    if (this.jogo === 'megasena') return false;

    if (this.jogo === 'duplasena' && index == 6) return true;

    if (this.jogo === 'duplasena' && index != 6) return false;

    if (index % 5 == 0) return true;

    return false;
  }
}
