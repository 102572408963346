import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-not-found-data',
  templateUrl: './not-found-data.component.html',
  styleUrls: ['./not-found-data.component.scss'],
})
export class NotFoundDataComponent {
  @Input('mensagem') mensagem: string;
}
