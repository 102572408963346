<div
  class="relative flex flex-col flx-col-0 sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
  <div class="text-4xl font-extrabold tracking-tight">{{ pageTitle }}</div>

  <div class="flex flex-col sm:flex-row items-start shrink-0 sm:items-center mt-6 sm:mt-0 sm:ml-4">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64" *ngIf="showSearch">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput id="searchPerfil" #temoPesquisa (keyup)="filtrar(temoPesquisa.value)" [autocomplete]="'off'"
        [placeholder]="placeholder" />
    </mat-form-field>
    <!-- Add product button -->
    <a [routerLink]="['novo']" routerLinkActive="router-link-active" *ngIf="showButton"
      class="mat-focus-indicator mt-2 fuse-mat-button-rounded mat-flat-button mat-button-base mat-accent m-1 text-1xl"
      mat-flat-button>
      <span class="p-3 flex flex-row items-center">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        Cadastrar
      </span>
    </a>
  </div>
</div>