<div class="flex flex-col h-full">

  <div class="flex flex-col flex-auto w-full mx-auto" *ngIf="resultado?.premiacoes">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3 bg-gray-200 dark:bg-gray-800">
              Premiações
            </th>
            <th scope="col" class="px-6 py-3 bg-gray-200 dark:bg-gray-800 border-l border-gray-300">
              Ganhadores
            </th>
            <th scope="col" class="px-6 py-3 bg-gray-200 dark:bg-gray-800 border-l border-gray-300">
              Prêmios
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b border-gray-200 dark:border-gray-700" *ngFor="let premiacao of resultado?.premiacoes">
            <th scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
              {{ premiacao?.descricao }}
            </th>
            <td class="px-6 py-4">
              {{ premiacao?.ganhadores }}
            </td>
            <td class="px-6 py-4 bg-gray-50 dark:bg-gray-800">
              {{ premiacao?.valorPremio | currency: 'R$ ' }}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>

  <div class="flex flex-col flex-auto w-full mx-auto mt-3" *ngIf="resultado?.estadosPremiados?.length > 0">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" colspan="3"
              class="text-center px-6 py-3 bg-gray-200 dark:bg-gray-800 border-b border-gray-300">
              Ganhadores por Estado
            </th>
          </tr>
          <tr>
            <th scope="col" class="text-center px-6 py-3 bg-gray-200 dark:bg-gray-800 border-gray-300">
              Estado
            </th>
            <th scope="col" class="text-center px-6 py-3 bg-gray-200 dark:bg-gray-800 border-l border-gray-300">
              Ganhadores
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b border-gray-200 dark:border-gray-700" *ngFor="let estado of resultado?.estadosPremiados">
            <th scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
              {{ estado?.nome }} ({{ estado?.uf }})
            </th>
            <td class="px-6 py-4">
              {{ estado?.vencedores }}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div>
