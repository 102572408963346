import { Component, Input, OnInit } from '@angular/core';
import { IResultado } from 'app/shared/models/interface.model';

@Component({
  selector: 'app-tabela-resultado',
  templateUrl: './tabela-resultado.component.html',
  styleUrls: ['./tabela-resultado.component.scss'],
})
export class TabelaResultadoComponent implements OnInit {
  @Input('resultado') resultado: IResultado = {} as IResultado;

  constructor() {}

  ngOnInit(): void {}
}
