import { Component, Input, OnInit } from '@angular/core';
import { ETipoJogo } from 'app/shared/models/interface.model';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent implements OnInit {
  @Input() jogo: ETipoJogo = ETipoJogo.LOTOFACIL;
  @Input() titulo = 'Lotofácil';
  @Input('sub-titulo') subTitulo = '';

  constructor() {}

  ngOnInit(): void {}
}
