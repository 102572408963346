export interface IFilter {
  filtro: string;
  pagina: number;
  tamanho: number;
  ordenarPor: string;
  direcaoOrdenacao: 'ASC' | 'DESC';
}

export interface IResultado {
  loteria: string;
  concurso: number;
  data: string;
  local: string;
  dezenasOrdemSorteio: string[];
  dezenas: string[];
  trevos: any[];
  timeCoracao: null;
  mesSorte: string;
  premiacoes: IPremiacao[];
  estadosPremiados: any[];
  observacao: string;
  acumulou: boolean;
  proximoConcurso: number;
  dataProximoConcurso: string;
  localGanhadores: IEstadosPremiados[];
  valorArrecadado: number;
  valorAcumuladoConcurso_0_5: number;
  valorAcumuladoConcursoEspecial: number;
  valorAcumuladoProximoConcurso: number;
  valorEstimadoProximoConcurso: number;
}

export interface IEstadosPremiados {
  ganhadores: number;
  municipio: string;
  nomeFatansiaUL: string;
  serie: string;
  posicao: number;
  uf: string;
}

export interface IPremiacao {
  descricao: string;
  faixa: number;
  ganhadores: number;
  valorPremio: number;
}

export enum ETipoJogo {
  DUPLA_SENA = 'duplasena',
  LOTOFACIL = 'lotofacil',
  LOTO_MANIA = 'lotomania',
  QUINA = 'quina',
  MEGA_SENA = 'megasena',
  TIME_MANIA = 'timemania',
  DIA_DE_SORTE = 'diadesorte',
  SUPER_SETE = 'supersete',
}

export interface ITipoCardJogos {
  tipo: string;
  titulo: string;
  descricao: string;
  rota: string;
  cor: string;
}

export interface INumerosCartela {
  numero: number;
  selecionado: boolean;
}

export interface ICartela {
  cartela: INumerosCartela[];
  selecionados: number[];
}

export interface IRequestJogos {
  cartela: Array<number[]>;
}
