/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { ESessionStorage } from 'app/shared/models/sessionstorage.model';
import { AuthUtils } from '../auth/auth.utils';

export const menuNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'loteria-logo',
    link: '/home',
    hidden: () => desabilitaMenu('HOME'),
  },
  {
    id: 'administracao',
    title: 'Administração',
    type: 'collapsable',
    icon: 'heroicons_outline:cog',
    hidden: () => desabilitaMenu('MENU_ADMINISTRACAO'),
    children: [
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/perfil',
        hidden: () => desabilitaMenu('PERFIL'),
      },
      {
        id: 'permissao',
        title: 'Permissão',
        type: 'basic',
        icon: 'heroicons_outline:lock-closed',
        link: '/permissao',
        hidden: () => desabilitaMenu('PERMISSAO'),
      },
      {
        id: 'perfil-permissao',
        title: 'Perfil/Permissão',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/perfil-permissao',
        hidden: () => desabilitaMenu('PERFIL_PERMISSAO'),
      },
      {
        id: 'usuario',
        title: 'Usuário',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/usuario',
        hidden: () => desabilitaMenu('USUARIO'),
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapsable',
        icon: 'heroicons_outline:document-text',
        hidden: () => desabilitaMenu('LOG'),
        children: [
          {
            id: 'log.acesso',
            title: 'Acesso',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/acesso',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_ACESSO'),
          },
          {
            id: 'log.historico',
            title: 'Histórico',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/historico',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_HISTORICO'),
          },
        ],
      },
    ],
  },
  {
    id: 'duplasena',
    title: 'Dupla Sena',
    type: 'collapsable',
    icon: 'logo-duplasena',
    children: [
      {
        id: 'duplasena.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('DUPLA_SENA'),
  },
  {
    id: 'loto-facil',
    title: 'LotoFácil',
    type: 'collapsable',
    icon: 'logo-lotofacil',
    children: [
      {
        id: 'loto-facil.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('LOTOFACIL'),
  },
  {
    id: 'lotomania',
    title: 'Loto Mania',
    type: 'collapsable',
    icon: 'logo-lotomania',
    children: [
      {
        id: 'lotomania.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('LOTOMANIA'),
  },
  {
    id: 'megasena',
    title: 'Mega Sena',
    type: 'collapsable',
    icon: 'logo-megasena',
    children: [
      {
        id: 'megasena.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('MEGA_SENA'),
  },
  {
    id: 'quina',
    title: 'Quina',
    type: 'collapsable',
    icon: 'logo-quina',
    children: [
      {
        id: 'quina.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('QUINA'),
  },
  {
    id: 'diadesorte',
    title: 'Dia de Sorte',
    type: 'collapsable',
    icon: 'logo-diadesorte',
    children: [
      {
        id: 'diadesorte.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'diadesorte/resultado',
      },
    ],
    hidden: () => desabilitaMenu('DIA_DE_SORTE'),
  },
  {
    id: 'supersete',
    title: 'Super Sete',
    type: 'collapsable',
    icon: 'logo-supersete',
    children: [
      {
        id: 'supersete.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'supersete/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('SUPER_SETE'),
  },
  {
    id: 'timemania',
    title: 'Timemania',
    type: 'collapsable',
    icon: 'logo-timemania',
    children: [
      {
        id: 'timemania.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'timemania/resultado',
      },
    ],
    hidden: () => desabilitaMenu('TIMEMANIA'),
  },
];

export const adminNavigation: FuseNavigationItem[] = [
  {
    id: 'admin-home',
    title: 'Admin',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];

export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
    hidden: () => desabilitaMenu('HOME'),
  },
  {
    id: 'administracao',
    title: 'Administração',
    type: 'aside',
    icon: 'heroicons_outline:cog',
    hidden: () => desabilitaMenu('MENU_ADMINISTRACAO'),
    children: [
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/perfil',
        hidden: () => desabilitaMenu('PERFIL'),
      },
      {
        id: 'permissao',
        title: 'Permissão',
        type: 'basic',
        icon: 'heroicons_outline:lock-closed',
        link: '/permissao',
        hidden: () => desabilitaMenu('PERMISSAO'),
      },
      {
        id: 'perfil-permissao',
        title: 'Perfil/Permissão',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/perfil-permissao',
        hidden: () => desabilitaMenu('PERFIL_PERMISSAO'),
      },
      {
        id: 'usuario',
        title: 'Usuário',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/usuario',
        hidden: () => desabilitaMenu('USUARIO'),
      },
      {
        id: 'log',
        title: 'Log',
        type: 'collapsable',
        icon: 'heroicons_outline:document-text',
        hidden: () => desabilitaMenu('LOG'),
        children: [
          {
            id: 'log.acesso',
            title: 'Acesso',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/acesso',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_ACESSO'),
          },
          {
            id: 'log.historico',
            title: 'Histórico',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: '/log/historico',
            hidden: () => desabilitaMenu('PESQUISAR_LOG_HISTORICO'),
          },
        ],
      },
    ],
  },
  {
    id: 'cliente',
    title: 'cliente',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/cliente',
    hidden: () => desabilitaMenu('CLIENTE'),
  },
  {
    id: 'grupo',
    title: 'Grupo',
    type: 'aside',
    icon: 'heroicons_outline:user',
    link: '/grupo',
    hidden: () => desabilitaMenu('GRUPO'),
    children: [
      {
        id: 'servico',
        title: 'Serviço',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/grupo/servico',
        hidden: () => desabilitaMenu('SERVICO'),
      },
      {
        id: 'unidade',
        title: 'Unidade',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/unidade',
        // hidden: () => desabilitaMenu('UNIDADE'),
      },
      {
        id: 'tipo',
        title: 'Tipo',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/grupo/tipo',
        hidden: () => desabilitaMenu('TIPO'),
      },
    ],
  },
  {
    id: 'ordem-servico',
    title: 'Ordem Serviço',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/grupo/ordem-servico',
    hidden: () => desabilitaMenu('ORDEM_SERVICO'),
  },
  {
    id: 'duplasena',
    title: 'Dupla Sena',
    type: 'aside',
    icon: 'logo-duplasena',
    children: [
      {
        id: 'duplasena.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/resultado',
      },
      {
        id: 'duplasena.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'duplasena.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'duplasena/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('DUPLA_SENA'),
  },
  {
    id: 'loto-facil',
    title: 'LotoFácil',
    type: 'aside',
    icon: 'logo-lotofacil',
    children: [
      {
        id: 'loto-facil.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'loto-facil.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'loto-facil/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('LOTOFACIL'),
  },
  {
    id: 'lotomania',
    title: 'Loto Mania',
    type: 'aside',
    icon: 'logo-lotomania',
    children: [
      {
        id: 'lotomania.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/resultado',
        // hidden: () => desabilitaMenu('LOTOMANIA'),
      },
      {
        id: 'lotomania.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'lotomania.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'lotomania/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('LOTOMANIA'),
  },
  {
    id: 'megasena',
    title: 'Mega Sena',
    type: 'aside',
    icon: 'logo-megasena',
    children: [
      {
        id: 'megasena.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'megasena.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'megasena/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('MEGA_SENA'),
  },
  {
    id: 'quina',
    title: 'Quina',
    type: 'aside',
    icon: 'logo-quina',
    children: [
      {
        id: 'quina.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.gerar-numeros',
        title: 'Gerador de Números',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/gerador',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.tabelas',
        title: 'Tabelas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/tabelas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.ferramentas',
        title: 'Ferramentas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/ferramentas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
      {
        id: 'quina.dicas',
        title: 'Dicas',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'quina/dicas',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('QUINA'),
  },
  {
    id: 'diadesorte',
    title: 'Dia de Sorte',
    type: 'aside',
    icon: 'logo-diadesorte',
    children: [
      {
        id: 'diadesorte.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'diadesorte/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('DIA_DE_SORTE'),
  },
  {
    id: 'supersete',
    title: 'Super Sete',
    type: 'aside',
    icon: 'logo-supersete',
    children: [
      {
        id: 'supersete.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'supersete/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('SUPER_SETE'),
  },
  {
    id: 'timemania',
    title: 'Timemania',
    type: 'aside',
    icon: 'logo-timemania',
    children: [
      {
        id: 'timemania.resultado',
        title: 'Resultado',
        type: 'basic',
        icon: 'loteria-logo',
        link: 'timemania/resultado',
        // hidden: () => desabilitaMenu('LOTOFACIL'),
      },
    ],
    hidden: () => desabilitaMenu('TIMEMANIA'),
  },
];

function desabilitaMenu(permissao: string): boolean {
  const token = sessionStorage.getItem(ESessionStorage.TOKEN);
  if (!token) {
    return true;
  }

  const { ROLES } = AuthUtils.decodeToken(token);
  if (Object(ROLES).find((p) => p == permissao)) {
    return false;
  }

  return true;
}
