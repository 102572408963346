<!-- Header -->
<div class="relative flex-0 py-5 overflow-hidden bg-{{jogo}}-100">
  <!-- Background - @formatter:off -->
  <!-- Rings -->
  <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
    preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
    <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
      <circle r="234" cx="196" cy="23"></circle>
      <circle r="234" cx="790" cy="491"></circle>
    </g>
  </svg>
  <!-- @formatter:on -->
  <div class="z-10 relative flex flex-col items-center">
    <div class="mt-1 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center text-white">
      {{ titulo }}
      <h2 class="text-xl font-semibold text-white" *ngIf="subTitulo !== ''">
        {{ subTitulo }}
      </h2>
    </div>
  </div>
</div>