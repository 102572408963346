import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { ELocalStorage } from 'app/shared/models/localstorage.model';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { AuthUtils } from '../auth/auth.utils';
import { ESessionStorage } from 'app/shared/models/sessionstorage.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  private _userAvatarUrl: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(private _httpClient: HttpClient) {}

  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get userAvatarUrl$(): Observable<string> {
    return this._userAvatarUrl.asObservable();
  }

  get(): Observable<User> {
    const { userId, ROLES } = AuthUtils.decodeToken(
      sessionStorage.getItem(ESessionStorage.TOKEN)
    );

    return this._httpClient
      .get<User>(`${environment.apiAutenticacao}/usuarios/${userId}`)
      .pipe(
        tap((user: User) => {
          this._user.next(user);

          if (Object(ROLES).find((permissao) => permissao === 'HOME')) {
          }
        })
      );
  }
}
