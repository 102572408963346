import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { IResultado } from '../models/interface.model';

@Injectable({
  providedIn: 'root',
})
export class ResultadoService {
  constructor(private http: HttpClient) {}

  getResultadoConcurso(
    loteria: string,
    concurso: number | string
  ): Observable<IResultado> {
    return this.http.get<IResultado>(
      `${env.apiResultados}/${loteria}/${concurso}`
    );
  }

  getTodosResultados(loteria: string): Observable<IResultado[]> {
    return this.http.get<IResultado[]>(`${env.apiResultados}/${loteria}`);
  }
}
