import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INumerosCartela } from 'app/shared/models/interface.model';

@Component({
  selector: 'app-cartela',
  templateUrl: './cartela.component.html',
  styleUrls: ['./cartela.component.scss'],
})
export class CartelaComponent implements OnInit {
  @Input() jogo: string = 'lotofacil';
  @Input() numerosCartela: INumerosCartela[] = [];

  @Input() numerosSelecionados: number[] = [];

  @Input('pulo') numeroParaPuloDeLinha: number = 5;

  @Output() selecionados = new EventEmitter();

  classItemSelecionado: string = '';

  constructor() {}

  ngOnInit(): void {
    this.classItemSelecionado = 'bg-' + this.jogo + '-100 font-bold';
  }

  adicionarRemoverNumero(item: INumerosCartela): void {
    const index = this.numerosCartela.findIndex((numero) => numero === item);
    if (this.numerosCartela[index].selecionado) {
      const indexOf = this.numerosSelecionados.indexOf(
        this.numerosCartela[index].numero
      );

      if (indexOf !== -1) {
        this.numerosSelecionados.splice(indexOf, 1);
      }
    }

    if (!this.numerosCartela[index].selecionado) {
      this.numerosSelecionados.push(this.numerosCartela[index].numero);
    }

    this.numerosCartela[index].selecionado =
      !this.numerosCartela[index].selecionado;

    this.selecionados.emit(this.numerosSelecionados);
  }

  get par(): number {
    let totalPar: number = 0;
    this.numerosSelecionados.forEach((n) => {
      if (n % 2 === 0) totalPar += 1;
    });

    return totalPar;
  }

  get impar(): number {
    let totalImpar: number = 0;
    this.numerosSelecionados.forEach((n) => {
      if (n % 2 !== 0) totalImpar += 1;
    });

    return totalImpar;
  }

  get total(): number {
    let total: number = 0;
    this.numerosSelecionados.forEach((n) => {
      total += n;
    });

    return total;
  }
}
