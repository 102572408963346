<!-- Header -->
<div class="relative flex-0 py-5 overflow-hidden bg-{{jogo}}-100">
  <!-- Background - @formatter:off -->
  <!-- Rings -->
  <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
    preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
    <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
      <circle r="234" cx="196" cy="23"></circle>
      <circle r="234" cx="790" cy="491"></circle>
    </g>
  </svg>
  <!-- @formatter:on -->
  <div class="z-10 relative flex flex-col items-center">
    <div class="mt-1 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center text-white">
      {{ titulo }}
    </div>
    <div class="max-w-2xl mt-6 sm:text-2xl text-center tracking-tight text-secondary">
      <h2 class="text-xl font-semibold text-white">
        Concurso: {{ resultado.concurso }} – {{ resultado.data }}
      </h2>
      <div class="h-[35%] w-full mb-5 pt-3 flex flex-col items-center justify-center">
        <p class="flex flex-wrap justify-around w-full">
          <ng-container *ngFor="let numero of resultado?.dezenas; let i = index">
            <div class="basis-[100%] h-0" *ngIf="pularLinha(i)"></div>
            <div class="item rounded-full m-1 bg-white w-9 h-9 flex items-center justify-center font-bold text-2xl">
              {{ numero }}
            </div>
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</div>