import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input('page-title') pageTitle: string = '';
  @Input('show-button') showButton: boolean = true;
  @Input('show-search') showSearch: boolean = true;
  @Input('placeholder-text') placeholder: string = '';

  @Output() pesquisar = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  filtrar(termo: string): void {
    this.pesquisar.emit(termo);
  }
}
