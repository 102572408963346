import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'firstLastNames' })
export class FirstLastNames implements PipeTransform {
  transform(nomeCompleto: string): string {
    const nomes = nomeCompleto.split(' ');

    return `${nomes[0]} ${nomes[nomes.length - 1]}`;
  }
}
