<!-- CARTELA -->
<fuse-card
  class="flex flex-col items-center  max-w-48 {{ numeroParaPuloDeLinha == 5 ? 'max-w-48' : 'max-w-90'}} p-3 bg-cartela border-{{jogo}}-200 border-2">
  <p class="flex flex-wrap w-full items-center justify-center">
    <ng-container *ngFor="let item of numerosCartela">
      <div
        class="cursor-pointer item text-{{jogo}}-300 rounded-lg border border-{{jogo}}-200 {{item.selecionado ? classItemSelecionado : ''}} m-1 w-6 h-7 flex items-center justify-center text-base"
        [ngClass]="{'bg-white': !item.selecionado}" (click)="adicionarRemoverNumero(item)">
        {{ item.numero }}
      </div>
      <div class="basis-[100%] h-0" *ngIf="item.numero % numeroParaPuloDeLinha === 0"></div>
    </ng-container>
  </p>
  <p class="text-sm text-{{jogo}}-300 mt-3">Par:{{par}} Impar:{{impar}} | Soma:{{total}}</p>
</fuse-card>