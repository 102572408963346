import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry
  ) {
    // Register icon sets
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-twotone.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-outline.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-solid.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/feather.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-outline.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-solid.svg'
      )
    );

    // ICONES DE LOTERIA
    this._matIconRegistry.addSvgIcon(
      'loteria-logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/loteria.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-diadesorte',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/diadesorte.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-duplasena',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/duplasena.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-lotofacil',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/loto-facil.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-lotomania',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/lotomania.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-megasena',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/megasena.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-quina',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/quina.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-supersete',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/supersete.svg'
      )
    );

    this._matIconRegistry.addSvgIcon(
      'logo-timemania',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/loteria/timemania.svg'
      )
    );
  }
}
